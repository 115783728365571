






















































































import { defineComponent, reactive } from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(_, { root }) {
    const state = reactive({
      building: false,
    });

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, ["pl-PL"]) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
    };

    return { state, rules };
  },
});
